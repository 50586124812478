import Link from 'next/link';
import { Button } from '@wearemotivated/design-system/redesign/components/Button';

type ReturnHomeButtonProps = {
  onClick?: () => void;
};

export const ReturnHomeButton = ({ onClick }: ReturnHomeButtonProps) => {
  return (
    <Button asChild>
      <Link href="/" onClick={onClick}>
        Return Home
      </Link>
    </Button>
  );
};
