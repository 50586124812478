'use client';

import { GoogleTagManager } from '@next/third-parties/google';
import Script from 'next/script';
import { usePathname } from 'next/navigation';

type RegisterGoogleTagManagerProps = {
  gtmId?: string;
  gtmServer?: string;
};

export const RegisterGoogleTagManager = ({
  gtmId,
  gtmServer,
}: RegisterGoogleTagManagerProps) => {
  const pathname = usePathname();
  const tagManagerId = gtmId || '';
  const server = gtmServer || 'www.googletagmanager.com';

  if (tagManagerId == null || tagManagerId == '') {
    console.log('No Google Tag Manager ID found');
    return null;
  }

  if (pathname.startsWith('/blog')) {
    return null;
  }

  if (server == null || server == '' || server == 'www.googletagmanager.com') {
    return <GoogleTagManager gtmId={tagManagerId} />;
  }

  return (
    <>
      <Script
        id="gtm-script"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://${server}/gtm.js?id=${tagManagerId}';j.onload=function(){console.log("Google Tag Manager loaded successfully")};
            f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer', '${tagManagerId}');
          `,
        }}
      />
    </>
  );
};
