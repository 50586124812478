'use client';
import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IN_MEMORY_SESSION_TOKEN_HEADER_NAME } from '@/redesign/lib/constants';

export type InMemorySessionContextType = {
  sessionToken: string | null;
  trackingData?: TrackingData;
  setSessionToken: (token: string | null) => void;
  extractSessionToken: (res?: Response) => void;
};

const InMemorySessionContext = createContext<InMemorySessionContextType>({
  sessionToken: null,
  setSessionToken: () => {},
  extractSessionToken: () => {},
});

type TrackingData = {
  referrer?: string | null;
  landingPage?: string | null;
};

/* fallback session token storage if cookies not available. allowing a user to complete the checkout */
export const InMemorySessionProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [sessionToken, setSessionToken] = useState<string | null>(null);
  const [trackingData, setTrackingData] = useState<TrackingData>();

  useEffect(() => {
    setTrackingData({
      referrer: btoa(document.referrer ?? 'unknown'),
      landingPage: btoa(window.location.href ?? 'unknown'),
    });
  }, []);

  const extractSessionToken = useCallback((res?: Response) => {
    if (res?.headers.has(IN_MEMORY_SESSION_TOKEN_HEADER_NAME)) {
      setSessionToken(res.headers.get(IN_MEMORY_SESSION_TOKEN_HEADER_NAME));
    }
  }, []);
  return (
    <InMemorySessionContext
      value={{
        sessionToken,
        trackingData,
        setSessionToken,
        extractSessionToken,
      }}>
      {children}
    </InMemorySessionContext>
  );
};

export const useInMemorySession = () => {
  return useContext(InMemorySessionContext);
};
