'use client';

import { useEffect } from 'react';

const rootDomainsToLogAsWarning = [
  'googleadservices.com',
  'googletagmanager.com',
  'google.com',
];

export const ScriptErrorListener = () => {
  useEffect(() => {
    window.addEventListener(
      'error',
      function (event) {
        if (
          event.target instanceof HTMLScriptElement &&
          event.target.tagName.toLowerCase() === 'script'
        ) {
          const scriptUrl = event.target.src;
          const scriptDomain = new URL(scriptUrl).hostname;
          const shouldBeWarning = rootDomainsToLogAsWarning.some((rootDomain) =>
            scriptDomain.endsWith(rootDomain),
          );

          const logFunc = shouldBeWarning ? console.warn : console.error;
          logFunc('Unexpected runtime script error: ', {
            scriptUrl,
            scriptDomain,
            error: event.error,
          });
        }
      },
      true,
    );
  }, []);

  return null;
};
