import { Footer, LinkItem } from '@strapi-schema';
import { InstagramIcon } from '@wearemotivated/design-system/redesign/icons/Instagram';
import { TwitterIcon } from '@wearemotivated/design-system/redesign/icons/Twitter';
import { FacebookIcon } from '@wearemotivated/design-system/redesign/icons/Facebook';
import { TiktokIcon } from '@wearemotivated/design-system/redesign/icons/Tiktok';
import { YoutubeIcon } from '@wearemotivated/design-system/redesign/icons/Youtube';

const getSocialIconByText = (text?: string) => {
  switch (text) {
    case 'facebook':
      return FacebookIcon;
    case 'twitter':
      return TwitterIcon;
    case 'instagram':
      return InstagramIcon;
    case 'tiktok':
      return TiktokIcon;
    case 'youtube':
      return YoutubeIcon;
    default:
      return null;
  }
};

export const parseFooterData = (data: Footer) => {
  return {
    bottomLinks:
      data.attributes?.bottomLinks?.items.map((i: LinkItem) => ({
        text: i.text ?? '',
        url: i.url ?? '',
      })) ?? [],
    socialLinks:
      data.attributes?.socialsLinks?.items.map((i: LinkItem) => ({
        text: i.text ?? '',
        url: i.url ?? '',
        icon: getSocialIconByText(i.text?.toLocaleLowerCase()),
      })) ?? [],
    learnMoreLinks:
      data.attributes?.learnMoreLinks?.items.map((i: LinkItem) => ({
        text: i.text ?? '',
        url: i.url ?? '',
      })) ?? [],
    socialLinksTitle: data.attributes?.socialsLinksTitle ?? '',
    learnMoreTitle: data.attributes?.learnMoreLinksColumnTitle ?? '',
    copyrightsText: data.attributes?.copyrightsText ?? '',
    missionStatement: data.attributes?.missionStatement ?? '',
    supportColumnDescription: data.attributes?.supportColumnDescription ?? '',
    supportColumnTitle: data.attributes?.supportColumnTitle ?? '',
    disclaimerText: data.attributes?.disclaimerText,
  };
};
