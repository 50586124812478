import React, { HTMLAttributes } from 'react';
import { MarkdownText } from '@wearemotivated/design-system/redesign/components/MarkdownText';
import { LegitScript } from '@wearemotivated/design-system/redesign/components/LegitScript';
import { Footer as StrapiFooter } from '@strapi-schema';
import { parseFooterData } from '../lib/utils/footer';
import Link from 'next/link';
import { MotivatedLogo } from '@wearemotivated/design-system/redesign/logos/Motivated';
import { cn } from '@/redesign/lib/utils/style';

export interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  data: StrapiFooter;
  pageDisclaimer?: string | null;
}

export const Footer = ({
  data,
  className,
  pageDisclaimer,
  ...props
}: FooterProps) => {
  const {
    missionStatement,
    learnMoreTitle,
    learnMoreLinks,
    supportColumnTitle,
    supportColumnDescription,
    socialLinksTitle,
    socialLinks,
    bottomLinks,
    copyrightsText,
    disclaimerText,
  } = parseFooterData(data);

  return (
    <div className={cn('wf-pt-36 sm:wf-pt-24', className)} {...props}>
      <div className="wf-relative wf-mx-auto wf-max-w-[1440px] wf-px-8 lg:wf-px-24">
        <div className="wf-grid wf-grid-cols-1 wf-gap-x-16 wf-gap-y-8 wf-border-b wf-border-rdsn-gray-200 wf-pb-8 md:wf-grid-cols-5 md:wf-gap-20 md:wf-gap-x-4 md:wf-pb-12 lg:wf-gap-x-14">
          <div className="wf-mb-8 wf-flex wf-flex-col wf-items-start wf-gap-5">
            <Link href="/" aria-label="homepage">
              <MotivatedLogo className="wf-h-5 wf-w-auto" />
            </Link>
            {!!missionStatement && (
              <p className="wf-text-center wf-font-gothic wf-text-rdsn-h5 wf-text-rdsn-black sm:wf-text-left">
                {missionStatement}
              </p>
            )}
          </div>
          <div className="wf-col-span-1 wf-flex wf-flex-col md:wf-col-span-2">
            <h3 className="wf-mb-4 wf-font-gothic wf-text-rdsn-prod-sub wf-font-medium wf-text-rdsn-black">
              {learnMoreTitle}
            </h3>
            {learnMoreLinks.map((popularTreatment, index) => (
              <a
                className="wf-mb-4 wf-self-start wf-whitespace-nowrap wf-font-gothic wf-text-rdsn-body wf-text-rdsn-black last-of-type:wf-mb-0"
                key={index}
                href={popularTreatment.url}
                rel="noopener noreferrer">
                {popularTreatment.text}
              </a>
            ))}
          </div>

          <div className="wf-flex wf-flex-col">
            <h3 className="wf-mb-2 wf-font-gothic wf-text-rdsn-prod-sub wf-font-medium wf-text-rdsn-black sm:wf-mb-4">
              {supportColumnTitle}
            </h3>
            <div className="wf-max-w-[177px] wf-font-gothic wf-text-rdsn-body-sm wf-text-rdsn-black sm:wf-max-w-fit">
              <MarkdownText
                className="wf-flex wf-flex-col wf-gap-3"
                components={{
                  p: ({ children }) => (
                    <p className="wf-flex wf-flex-col wf-gap-3 wf-text-rdsn-body-sm [&]:wf-text-[0.875rem]">
                      {children}
                    </p>
                  ),
                  a: ({ href, children }) => (
                    <a
                      className="wf-flex wf-flex-col wf-self-start wf-text-rdsn-body"
                      href={href}
                      rel="noopener noreferrer">
                      {children}
                    </a>
                  ),
                }}>
                {supportColumnDescription}
              </MarkdownText>
            </div>
          </div>
          <div className="wf-flex wf-flex-col">
            <h3 className="wf-mb-4 wf-font-gothic wf-text-rdsn-prod-sub wf-font-medium wf-text-rdsn-black">
              {socialLinksTitle}
            </h3>
            <div className="gap-4 wf-grid wf-grid-cols-[repeat(5,_minmax(0,_min-content))] wf-justify-start">
              {socialLinks.map((socialLink, index) => (
                <a
                  className={cn(
                    'wf-flex wf-h-5 wf-w-5 wf-items-center wf-justify-center wf-rounded-full',
                    socialLink.text === 'youtube' && 'wf-h-auto wf-w-[24px]',
                  )}
                  key={index}
                  href={socialLink.url}
                  rel="noopener noreferrer"
                  aria-label={socialLink.text}>
                  {socialLink.icon && <socialLink.icon />}
                </a>
              ))}
            </div>
          </div>
        </div>

        {disclaimerText && (
          <div className="wf-flex wf-flex-col wf-items-start wf-justify-between wf-gap-8 wf-border-b wf-border-rdsn-gray-200 wf-py-8 md:wf-flex-row md:wf-py-12">
            <p className="wf-max-w-[796px] wf-flex-1 wf-font-gothic wf-text-rdsn-body-sm wf-text-rdsn-black [&_strong]:wf-font-medium">
              <strong>Disclaimer: </strong>
              {disclaimerText}
            </p>
            <div className="wf-relative wf-h-20 wf-w-[5.8125rem]">
              <LegitScript />
            </div>
          </div>
        )}

        <div className="wf-flex wf-flex-col wf-items-start wf-gap-8 wf-pt-8 md:wf-flex-row md:wf-items-center md:wf-pt-12">
          <div className="pwf-x-4 wf-flex wf-items-center">
            <p className="wf-font-gothic wf-text-rdsn-h7 wf-text-rdsn-black">
              {copyrightsText}
            </p>
          </div>
          <div className="wf-flex wf-w-full wf-flex-1 wf-flex-col wf-flex-wrap wf-gap-x-8 wf-gap-y-4 sm:wf-w-auto md:wf-flex-row md:wf-justify-end">
            {bottomLinks.map((bottomLink, index) => (
              <a
                className="wf-font-gothic wf-text-rdsn-h7 wf-text-rdsn-black"
                key={index}
                href={bottomLink.url}
                rel="noopener noreferrer">
                {bottomLink.text}
              </a>
            ))}
          </div>
        </div>

        <div className="wf-flex wf-flex-col wf-items-start wf-pb-24">
          {pageDisclaimer ? (
            <MarkdownText className="wf-mt-12 wf-break-all wf-border-t wf-border-rdsn-gray-200 wf-pt-[75px] wf-font-gothic wf-text-rdsn-body-sm wf-text-rdsn-gray-800 md:wf-mt-16 [&_li]:wf-mb-2 [&_li]:wf-list-decimal [&_strong]:wf-font-medium">
              {pageDisclaimer}
            </MarkdownText>
          ) : null}
        </div>
      </div>
    </div>
  );
};
