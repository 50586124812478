import * as React from 'react';
import { InfoIcon } from '@wearemotivated/design-system/redesign/icons/Info';
import { ReturnHomeButton } from '@/redesign/components/error/ReturnHomeButton';

type ErrorPageContentProps = {
  title: string;
  description: string;
  action?: React.ReactNode;
};

export const ErrorPageContent = ({
  title,
  description,
  action = <ReturnHomeButton />,
}: ErrorPageContentProps) => {
  return (
    <div className="px-4 wf-h-full wf-py-20 wf-text-center wf-font-gothic wf-text-rdsn-black">
      <h1 className="wf-text-rdsn-mob-h1 sm:wf-text-center sm:wf-text-rdsn-h1">
        {title}
      </h1>

      <p className="wf-mx-auto wf-flex wf-max-w-lg wf-flex-row wf-items-center wf-justify-center wf-pb-4 wf-pt-7 wf-text-center wf-text-rdsn-body-sm">
        <InfoIcon className="wf-mr-[0.5ch] wf-inline wf-h-[1.2em] wf-w-fit wf-shrink-0" />
        {description}
      </p>

      {action}
    </div>
  );
};
