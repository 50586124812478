'use client';

import * as React from 'react';
import { HeaderWithMenu } from '@/redesign/components/HeaderWithMenu';
import { ErrorPageContent } from '@/redesign/components/error/ErrorPageContent';
import { Footer } from '@/redesign/components/Footer';
import { type Footer as StrapiFooter } from '@strapi-schema';
import { type HeaderDataType } from '@/redesign/api/strapi/types';
import { ReturnHomeButton } from '@/redesign/components/error/ReturnHomeButton';

type ErrorBoundaryProps = {
  children: React.ReactNode;
  headerData: HeaderDataType;
  footerData: StrapiFooter;
};

const title = 'Something went wrong.';
const description = `
We encountered an unexpected error. 
Please try refreshing the page, or contact support if the problem persists. 
We’re working to fix this as quickly as possible.
`;

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    this.reset = this.reset.bind(this);
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error('Error boundary caught an error', {
      error,
      componentStack: info.componentStack,
    });
  }

  reset() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <div className="wf-relative">
            <HeaderWithMenu headerData={this.props.headerData} />
          </div>
          <ErrorPageContent
            title={title}
            description={description}
            action={<ReturnHomeButton onClick={this.reset} />}
          />
          <Footer data={this.props.footerData} />
        </div>
      );
    }

    return this.props.children;
  }
}
